export const spareThumb = {
    title: 'wanted: spare thumb',
    author: 'emmy',
    date: 'august 31, 2024',
    location: 'Burbank, CA',
    image: 'none',
    imageAlt: 'none',
    keywords: ['thumb', 'hitchhiking'],
    category: 'items wanted',
    body: 
    `
    wanted: spare thumb. planning on hitchhiking. need a backup
    `
};