export const eggGhost = {
    title: 'discussion: egg ghost?',
    author: 'emmy',
    date: 'august 31, 2024',
    location: 'Burbank, CA',
    image: 'none',
    imageAlt: 'none',
    keywords: ['egg', 'ghost'],
    category: 'discussion forums',
    body: 
    `
    anyone else getting haunted by an egg ghost? i think i have a ghost in my house and all he does is fuck with my eggs. 
    i make over easy eggs, he breaks my yolks. i make hard-boiled eggs, he peels them (that's the best part). 
    i make scrambled eggs, he eats them. he really likes them scrambled. 
    `
};