export const cinnabonChainmail = {
    title: 'Cinnabon: chainmail bodysuit',
    author: 'grace',
    date: 'september 17, 2023',
    location: 'Austin, TX',
    image: 'none',
    imageAlt: 'none',
    keywords: ['Cinnabon', 'chainmail', 'Austin', 'Texas'],
    category: 'missed connections',
    body: 
    `
    to the guy I spoke to at Cinnabon on Tuesday, where did you find a full chainmail bodysuit? Need one ASAP. Message me.
    `
};