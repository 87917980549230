export const windowCleaning = {
    title: 'window cleaning services?',
    author: 'grace',
    date: 'august 16, 2024',
    location: 'San Diego, CA',
    image: 'none',
    imageAlt: 'none',
    keywords: ['window', 'cleaning', 'lick'],
    category: 'services',
    body: 
    `
    Window cleaning services needed. Only requirement is that you have to lick them. Please call.
    `
};